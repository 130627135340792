const menuList =
    [
        {"title":"index","name":"首页","to":"/","children":[],"is_show": true},
        {
            "title": "setting", "name": "系统管理", "to": "/system", "children": [
                { "title": "", "name": "项目配置", "to": "/system/settings", "children": [], "is_show": false, "order": 1, "wx_url": null },
                { "title": "", "name": "用户管理", "to": "/system/user", "children": [], "is_show": false, "order": 2, "wx_url": null },
                // { "title": "", "name": "用户注册审批", "to": "/system/register", "children": [], "is_show": false, "order": 3, "wx_url": null },
                { "title": "", "name": "角色管理", "to": "/system/role", "children": [], "is_show": false, "order": 4, "wx_url": null },
                { "title": "", "name": "区域管理", "to": "/system/area", "children": [], "is_show": false, "order": 5, "wx_url": null },
                { "title": "", "name": "通道管理", "to": "/system/channel", "children": [], "is_show": false, "order": 6, "wx_url": null },
                { "title": "", "name": "来访理由管理", "to": "/system/reason", "children": [], "is_show": false, "order": 7, "wx_url": null },
                { "title": "", "name": "货物类型管理", "to": "/system/goodstype", "children": [], "is_show": false, "order": 8, "wx_url": null },
                // { "title": "", "name": "我的加入项目", "to": "/system/myjoin", "children": [], "is_show": false, "order": 9, "wx_url": null },
                // { "title": "", "name": "项目加入审批", "to": "/system/join", "children": [], "is_show": false, "order": 10, "wx_url": null },
                { "title": "", "name": "权限申请", "to": "/system/rolerecord", "children": [], "is_show": false, "order": 11, "wx_url": null },
                { "title": "", "name": "权限审批", "to": "/system/rolerecord/pending", "children": [], "is_show": false, "order": 12, "wx_url": null },
                { "title": "", "name": "消息通知管理", "to": "/system/message", "children": [], "is_show": false, "order": 13, "wx_url": null },
                
            ], "is_show": false, "order": 1, "wx_url": null
        },
        {
            "title": "heat-map", "name": "施工管理", "to": "/construction", "children": [
                { "title": "", "name": "工种管理", "to": "/construction/worktype", "children": [], "is_show": false, "order": 1, "wx_url": null },
                { "title": "", "name": "组织管理", "to": "/construction/contractor", "children": [], "is_show": false, "order": 2, "wx_url": null },
                { "title": "", "name": "工人管理", "to": "/construction/worker", "children": [], "is_show": false, "order": 3, "wx_url": null },
                { "title": "", "name": "工人审批", "to": "/construction/worker/pending", "children": [], "is_show": false, "order": 4, "wx_url": null },
                { "title": "", "name": "工人审批记录", "to": "/construction/worker/do", "children": [], "is_show": false, "order": 5, "wx_url": null },
                { "title": "", "name": "施工预约", "to": "/construction/order", "children": [], "is_show": false, "order": 6, "wx_url": null },
                { "title": "", "name": "施工审批", "to": "/construction/order/pending", "children": [], "is_show": false, "order": 7, },
                { "title": "", "name": "施工审批记录", "to": "/construction/order/do", "children": [], "is_show": false, "order": 8, "wx_url": null },
                { "title": "", "name": "施工浏览", "to": "/construction/viewproject", "children": [], "is_show": false, "order": 9, "wx_url": null },
                { "title": "", "name": "施工人员监控", "to": "/construction/viewlistener", "children": [], "is_show": false, "order": 10, "wx_url": null },
            ], "is_show": false, "order": 2, "wx_url": null
        },
        {
            "title": "team", "name": "人员管理", "to": "/event", "children": [
                { "title": "", "name": "散客登记", "to": "/event/fit", "children": [], "is_show": false, "order": 0, "wx_url": null },
                { "title": "", "name": "装卸货登记", "to": "/event/cargo", "children": [], "is_show": false, "order": 1, "wx_url": null },
                { "title": "", "name": "施工工人登记", "to": "/event/appointment", "children": [], "is_show": false, "order": 2, "wx_url": null },
                { "title": "", "name": "门禁授权管理", "to": "/event/authorization", "children": [], "is_show": false, "order": 3, "wx_url": null },
                { "title": "", "name": "人员出场", "to": "/event/accessrecord", "children": [], "is_show": false, "order": 4, "wx_url": null },
                { "title": "", "name": "我的访客", "to": "/event/myvisitor", "children": [], "is_show": false, "order": 5, "wx_url": null },
                { "title": "", "name": "访客浏览", "to": "/event/viewvisitor", "children": [], "is_show": false, "order": 6, "wx_url": null },
                { "title": "", "name": "装卸货浏览", "to": "/event/viewcargo", "children": [], "is_show": false, "order": 7, "wx_url": null },
                { "title": "", "name": "工人进出浏览", "to": "/event/viewworker", "children": [], "is_show": false, "order": 8, "wx_url": null },
                { "title": "", "name": "黑名单管理", "to": "/event/blanklist", "children": [], "is_show": false, "order": 9, "wx_url": null }
            ], "is_show": false, "order": 3, "wx_url": null
        },
        {
            "title": "book", "name": "培训管理", "to": "/training", "children": [
                { "title": "", "name": "试卷管理", "to": "/training/paper", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "资料管理", "to": "/training/material", "children": [], "is_show": false, "order": 2, },
                { "title": "", "name": "培训管理", "to": "/training/manage", "children": [], "is_show": false, "order": 3, },
                { "title": "", "name": "培训记录", "to": "/training/record", "children": [], "is_show": false, "order": 7, },
            ], "is_show": false, "order": 4, "wx_url": null
        },
        {
            "title": "table", "name": "工作流管理", "to": "/workflow", "children": [
                { "title": "", "name": "模板管理", "to": "/workflow/template", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "工作流配置", "to": "/workflow/manage", "children": [], "is_show": false, "order": 2, },
            ], "is_show": false, "order": 5, "wx_url": null
        },
        {
            "title": "check-square", "name": "巡更管理", "to": "/inspection", "children": [
                { "title": "", "name": "巡更点位", "to": "/inspection/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "巡更路线", "to": "/inspection/2", "children": [], "is_show": false, "order": 2, },
                { "title": "", "name": "巡更分组", "to": "/inspection/3", "children": [], "is_show": false, "order": 3, },
                { "title": "", "name": "巡更管理", "to": "/inspection/4", "children": [], "is_show": false, "order": 4, },
                { "title": "", "name": "我的巡更", "to": "/inspection/5", "children": [], "is_show": false, "order": 5, },
                { "title": "", "name": "巡更记录", "to": "/inspection/6", "children": [], "is_show": false, "order": 6, },
            ], "is_show": false, "order": 6
        },
        {
            "title": "bug", "name": "缺陷管理", "to": "/defects", "children": [
                { "title": "", "name": "缺陷类型管理", "to": "/defects/1", "children": [], "is_show": false, "order": 1 },
                { "title": "", "name": "我的缺陷工单", "to": "/defects/2", "children": [], "is_show": false, "order": 2 },
                { "title": "", "name": "待处理缺陷", "to": "/defects/3", "children": [], "is_show": false, "order": 3 },
                { "title": "", "name": "所有缺陷", "to": "/defects/4", "children": [], "is_show": false, "order": 4 }
            ], "is_show": false, "order": 7
        },
        {
            "title": "code-sandbox", "name": "TPM管理", "to": "/eqp", "children": [
                { "title": "", "name": "维修卡类型", "to": "/eqp/cardtype", "children": [], "is_show": false, "order": 16, "wx_url": null },
                { "title": "", "name": "供应商", "to": "/eqp/supplier", "children": [], "is_show": false, "order": 15, "wx_url": null },
                { "title": "", "name": "所有维修卡", "to": "/eqp/maintcard", "children": [], "is_show": false, "order": 14, "wx_url": null },
                { "title": "", "name": "我的维修卡", "to": "/eqp/mymaintcard", "children": [], "is_show": false, "order": 13, "wx_url": null },
                { "title": "", "name": "常用报表", "to": "/eqp/dashboard", "children": [], "is_show": false, "order": 12, "wx_url": null },
                { "title": "", "name": "我的维护记录", "to": "/eqp/mychecklist", "children": [], "is_show": false, "order": 8, "wx_url": null },
                { "title": "", "name": "所有维护记录", "to": "/eqp/checklist", "children": [], "is_show": false, "order": 9, "wx_url": null },
                { "title": "", "name": "维护类型", "to": "/eqp/mtype", "children": [], "is_show": false, "order": 7, "wx_url": null },
                { "title": "", "name": "所有任务单", "to": "/eqp/task", "children": [], "is_show": false, "order": 6, "wx_url": null },
                { "title": "", "name": "我的任务单", "to": "/eqp/mytask", "children": [], "is_show": false, "order": 5, "wx_url": null },
                { "title": "", "name": "任务包管理", "to": "/eqp/package", "children": [], "is_show": false, "order": 4, "wx_url": null },
                { "title": "", "name": "规则管理", "to": "/eqp/rule", "children": [], "is_show": false, "order": 3, "wx_url": null },
                { "title": "", "name": "系统设备", "to": "/eqp/syseqp", "children": [], "is_show": false, "order": 2, "wx_url": null },
                { "title": "", "name": "Key维护", "to": "/eqp/key", "children": [], "is_show": false, "order": 1, "wx_url": null }
            ], "is_show": false, "order": 8, "wx_url": null
        },
        {
            "title": "shopping-cart", "name": "材料进场(二期)", "to": "/logistics", "children": [
                { "title": "", "name": "设备材料注册", "to": "/logistics/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "设备材料审批", "to": "/logistics/2", "children": [], "is_show": false, "order": 2, },
                { "title": "", "name": "设备材料进场", "to": "/logistics/2", "children": [], "is_show": false, "order": 3, },
            ], "is_show": false, "order": 9
        },
        {
            "title": "fire", "name": "能源管理(二期)", "to": "/energy", "children": [
                { "title": "", "name": "用电管理", "to": "/energy/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "用水管理", "to": "/energy/2", "children": [], "is_show": false, "order": 2, },
                { "title": "", "name": "报警条件设置", "to": "/energy/3", "children": [], "is_show": false, "order": 3, },
            ], "is_show": false, "order": 10
        },
        {
            "title": "file", "name": "图纸管理(二期)", "to": "/papers", "children": [
                { "title": "", "name": "我的图纸", "to": "/papers/1", "children": [], "is_show": false, "order": 1 },
                { "title": "", "name": "所有图纸", "to": "/papers/2", "children": [], "is_show": false, "order": 2 }
            ], "is_show": false, "order": 11
        },
        {
            "title": "slack", "name": "SuperOpl(二期)", "to": "/super", "children": [
                { "title": "", "name": "escalation规则管理", "to": "/super/1", "children": [], "is_show": false, "order": 1 },
                { "title": "", "name": "我的OPL", "to": "/super/2", "children": [], "is_show": false, "order": 2 },
                { "title": "", "name": "所有OPL", "to": "/super/3", "children": [], "is_show": false, "order": 3 }
            ], "is_show": false, "order": 12
        },
        {
            "title": "usergroup-add", "name": "会议管理(二期)", "to": "/meeting", "children": [
                { "title": "", "name": "会议室管理", "to": "/metting/1", "children": [], "is_show": false, "order": 1 },
                { "title": "", "name": "我的会议", "to": "/metting/2", "children": [], "is_show": false, "order": 2 },
                { "title": "", "name": "会议室预订", "to": "/metting/2", "children": [], "is_show": false, "order": 3 }
            ], "is_show": false, "order": 13
        },
        {
            "title": "snippets", "name": "知识管理(二期)", "to": "/knowledge", "children": [
                { "title": "", "name": "子模块一", "to": "/knowledge/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "子模块二", "to": "/knowledge/2", "children": [], "is_show": false, "order": 2, },
            ], "is_show": false, "order": 14
        },
        {
            "title": "youtube", "name": "安全监控(二期)", "to": "/monitoring", "children": [
                { "title": "", "name": "子模块一", "to": "/monitoring/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "子模块二", "to": "/monitoring/2", "children": [], "is_show": false, "order": 2, },
            ], "is_show": false, "order": 15
        },
        {
            "title": "bank", "name": "商务管理(二期)", "to": "/business", "children": [
                { "title": "", "name": "子模块一", "to": "/business/1", "children": [], "is_show": false, "order": 1, },
                { "title": "", "name": "子模块二", "to": "/business/2", "children": [], "is_show": false, "order": 2, },
            ], "is_show": false, "order": 16
        },
    ]


export default menuList;