import { notificationInfo } from "@apis/system/push-message";

// 重要通知
const getNotification = ctx => {
  // notificationInfo().then(res => {
  //   const { need_handle_list, message_list } = res.data.results;

  //   if (ctx.props.menuState) {
  //     if (message_list) {
  //       ctx.props.menuState.changeMessageList(message_list);
  //     }
  //     if (need_handle_list) {
  //       ctx.props.menuState.changeNeedList(need_handle_list);
  //     }
  //   }
  // });
};

export default getNotification;
