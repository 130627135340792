import React, { createElement } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "mobx-react";

import SideLayout from "@view/side-layout/";
import PrivateRoute from "@view/authRouter";

import menuState from "./store/menu-state";
import appState from "./store/app-state";

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { inject, observer } from "mobx-react";

import zhCN from "antd/lib/locale-provider/zh_CN";
import { LocaleProvider } from "antd";
import { history } from "./store/app-state";

import Loadable from "react-loadable";

import { Spin } from "antd";
import "./App.css";
import "./App.less";
import CommonUtil from "@utils/common";

const dynamicWrapper = (component) => {
  return Loadable({
    loader: () => {
      return component().then((raw) => {
        const Component = raw.default || raw;
        return (props) =>
          createElement(Component, {
            ...props,
          });
      });
    },
    loading: () => {
      return <Spin size="large" className="global-spin" />;
    },
  });
};

const Login = dynamicWrapper(() => import("@view/login/"));
const RegisterPage = dynamicWrapper(() => import("@view/login/register"));
const Forget = dynamicWrapper(() => import("@view/forget/"));
// const ForgetStepTwo = dynamicWrapper(() => import( '@view/forget/step-two' ))
const ForbiddenPage = dynamicWrapper(() => import("@view/Exception/403"));
const NotFoundPage = dynamicWrapper(() => import("@view/Exception/404"));
const ErrorPage = dynamicWrapper(() => import("@view/Exception/500"));
const ErrorPageOut = dynamicWrapper(() => import("@view/Exception/500500"));
const WelcomePage = dynamicWrapper(() => import("@view/welcome/index"));

const PassWord = dynamicWrapper(() => import("@view/password"));

//系统管理员主页
const AdminHome = dynamicWrapper(() => import("@view/admin/home/"));
const AdminUser = dynamicWrapper(() => import("@view/admin/user/"));
const AdminProject = dynamicWrapper(() => import("@view/admin/project/"));
const AdminProjectAdd = dynamicWrapper(() => import("@view/admin/project/add"));
const AdminProjectEdit = dynamicWrapper(() =>
  import("@view/admin/project/edit")
);
//MJK管理员主页
const MyAdminHome = dynamicWrapper(() => import("@view/myadmin/home"));
const MyAdminUser = dynamicWrapper(() => import("@view/myadmin/user/"));
const MyAdminUserAdd = dynamicWrapper(() =>
  import("@view/myadmin/user/add-form")
);
const MyAdminProject = dynamicWrapper(() => import("@view/myadmin/project/"));
const MyAdminProjectAdd = dynamicWrapper(() =>
  import("@view/myadmin/project/add")
);
// const MyAdminProjectEdit = dynamicWrapper(() => import("@view/myadmin/project/edit"));
const MyAdminPermission = dynamicWrapper(() =>
  import("@view/myadmin/permission/")
);
const MyAdminPermissionAdd = dynamicWrapper(() =>
  import("@view/myadmin/permission/add-form")
);
const MyAdminPermissionNew = dynamicWrapper(() =>
  import("@view/myadmin/permission-new/")
);
const MyAdminPermissionNewAdd = dynamicWrapper(() =>
  import("@view/myadmin/permission-new/add-form")
);
const MyAdminPermissionPro = dynamicWrapper(() =>
  import("@view/myadmin/permission-pro/")
);
const Privacy = dynamicWrapper(() => import("@view/myadmin/privacy"));
const PrivacyAdd = dynamicWrapper(() => import("@view/myadmin/privacy/add"));
const PrivacyEdit = dynamicWrapper(() => import("@view/myadmin/privacy/edit"));
const PrivacyDetail = dynamicWrapper(() =>
  import("@view/myadmin/privacy/detail")
);
const adminFactory = dynamicWrapper(() => import("@view/myadmin/factory/"));
const adminFactoryAdd = dynamicWrapper(() =>
  import("@view/myadmin/factory/add-form")
);
const adminFactoryEdit = dynamicWrapper(() =>
  import("@view/myadmin/factory/edit-form")
);
const adminFactoryDetail = dynamicWrapper(() =>
  import("@view/myadmin/factory/detail")
);
const HelpList = dynamicWrapper(() => import("@view/myadmin/help/"));
const HelpAdd = dynamicWrapper(() => import("@view/myadmin/help/add-form"));
const HelpDetail = dynamicWrapper(() => import("@view/myadmin/help/detail"));
const BlankList = dynamicWrapper(() => import("@view/myadmin/blank-list/"));
const BlankListAdd = dynamicWrapper(() =>
  import("@view/myadmin/blank-list/add-form")
);
const BlankListEdit = dynamicWrapper(() =>
  import("@view/myadmin/blank-list/edit-form")
);
const LoginLog = dynamicWrapper(() => import("@view/myadmin/loginlog/"));
const LoginLogDetail = dynamicWrapper(() =>
  import("@view/myadmin/loginlog/detail")
);
const PrivacyLog = dynamicWrapper(() => import("@view/myadmin/privacylog/"));
const PrivacyLogDetail = dynamicWrapper(() =>
  import("@view/myadmin/privacylog/detail")
);
const OperationLog = dynamicWrapper(() => import("@view/myadmin/operation/"));
const OperationLogDetail = dynamicWrapper(() =>
  import("@view/myadmin/operation/detail")
);
const Version = dynamicWrapper(() => import("@view/myadmin/version/"));
const MaterialType = dynamicWrapper(() =>
  import("@view/myadmin/material/type")
);
const MaterialManage = dynamicWrapper(() =>
  import("@view/myadmin/material/manage")
);
const MaterialManageHandle = dynamicWrapper(() =>
  import("@view/myadmin/material/manage/handle")
);
const MaterialManageDetail = dynamicWrapper(() =>
  import("@view/myadmin/material/manage/detail")
);
const MaterialManageEdit = dynamicWrapper(() =>
  import("@view/myadmin/material/manage/edit")
);

//项目管理员主页
const Home = dynamicWrapper(() => import("@view/home/"));
const ProjectSetting = dynamicWrapper(() =>
  import("@view/system/project/edit-form")
);
const User = dynamicWrapper(() => import("@view/system/user/"));
const UserAdd = dynamicWrapper(() => import("@view/system/user/add-form"));
const ContractorAdd = dynamicWrapper(() =>
  import("@view/system/user/add-contractor")
);
const UserDetail = dynamicWrapper(() => import("@view/system/user/detail"));
const Feedback = dynamicWrapper(() => import("@view/system/feedback/"));
const FeedbackDetail = dynamicWrapper(() =>
  import("@view/system/feedback/detail")
);
const PushMessage = dynamicWrapper(() => import("@view/system/push-message/"));
const PushMessageAdd = dynamicWrapper(() =>
  import("@view/system/push-message/add-form")
);
const PushMessageDetail = dynamicWrapper(() =>
  import("@view/system/push-message/detail")
);

const Costcenter = dynamicWrapper(() => import("@view/system/costcenter/"));
const CostcenterAdd = dynamicWrapper(() =>
  import("@view/system/costcenter/add-form")
);
const CostcenterEdit = dynamicWrapper(() =>
  import("@view/system/costcenter/edit-form")
);
const CostcenterDetail = dynamicWrapper(() =>
  import("@view/system/costcenter/detail")
);
const Department = dynamicWrapper(() => import("@view/system/department/"));
const DepartmentAdd = dynamicWrapper(() =>
  import("@view/system/department/add-form")
);
const DepartmentEdit = dynamicWrapper(() =>
  import("@view/system/department/edit-form")
);
const DepartmentDetail = dynamicWrapper(() =>
  import("@view/system/department/detail")
);
const Location = dynamicWrapper(() => import("@view/system/location/"));
const LocationAdd = dynamicWrapper(() =>
  import("@view/system/location/add-form")
);
const LocationDetail = dynamicWrapper(() =>
  import("@view/system/location/detail")
);
const Area = dynamicWrapper(() => import("@view/system/area/"));
const AreaAdd = dynamicWrapper(() => import("@view/system/area/add-form"));
const AreaEdit = dynamicWrapper(() => import("@view/system/area/edit-form"));
const AreaDetail = dynamicWrapper(() => import("@view/system/area/detail"));
const Menu = dynamicWrapper(() => import("@view/system/menu/"));
const MenuAdd = dynamicWrapper(() => import("@view/system/menu/add-form"));
const MenuEdit = dynamicWrapper(() => import("@view/system/menu/edit-form"));
const MenuDetail = dynamicWrapper(() => import("@view/system/menu/detail"));
const Action = dynamicWrapper(() => import("@view/system/action/"));
const ActionAdd = dynamicWrapper(() => import("@view/system/action/add-form"));
const ActionEdit = dynamicWrapper(() =>
  import("@view/system/action/edit-form")
);
const ActionDetail = dynamicWrapper(() => import("@view/system/action/detail"));
const Permission = dynamicWrapper(() => import("@view/system/permission/"));
const PermissionAdd = dynamicWrapper(() =>
  import("@view/system/permission/add-form")
);
const PermissionEdit = dynamicWrapper(() =>
  import("@view/system/permission/edit-form")
);
const PermissionDetail = dynamicWrapper(() =>
  import("@view/system/permission/detail")
);
const Role = dynamicWrapper(() => import("@view/system/role/"));
const RoleAdd = dynamicWrapper(() => import("@view/system/role/add-form"));
const RoleDetail = dynamicWrapper(() => import("@view/system/role/detail"));
const Register = dynamicWrapper(() => import("@view/system/register/"));
const RegisterAudit = dynamicWrapper(() =>
  import("@view/system/register/audit")
);
const RegisterDetail = dynamicWrapper(() =>
  import("@view/system/register/detail")
);
const AllRegister = dynamicWrapper(() =>
  import("@view/system/register-record")
);
const AllRegisterDetail = dynamicWrapper(() =>
  import("@view/system/register-record/detail")
);
const AccessType = dynamicWrapper(() => import("@view/system/access-type/"));
const AccessTypeAdd = dynamicWrapper(() =>
  import("@view/system/access-type/add-form")
);
const AccessTypeEdit = dynamicWrapper(() =>
  import("@view/system/access-type/edit-form")
);
const AccessTypeDetail = dynamicWrapper(() =>
  import("@view/system/access-type/detail")
);
const Channel = dynamicWrapper(() => import("@view/system/channel/"));
const ChannelAdd = dynamicWrapper(() =>
  import("@view/system/channel/add-form")
);
const ChannelDetail = dynamicWrapper(() =>
  import("@view/system/channel/detail")
);
// const MapConfig = dynamicWrapper(() => import('@view/system/map/config'))
const MapArea = dynamicWrapper(() => import("@view/system/map/map"));

const Reason = dynamicWrapper(() => import("@view/system/reason/"));
const ReasonAdd = dynamicWrapper(() => import("@view/system/reason/add-form"));
const ReasonDetail = dynamicWrapper(() => import("@view/system/reason/detail"));
const GoodsType = dynamicWrapper(() => import("@view/system/goods-type/"));
const GoodsTypeAdd = dynamicWrapper(() =>
  import("@view/system/goods-type/add-form")
);
const GoodsTypeDetail = dynamicWrapper(() =>
  import("@view/system/goods-type/detail")
);
const ProjectJoin = dynamicWrapper(() => import("@view/system/myjoin/"));
const ProjectJoinAdd = dynamicWrapper(() =>
  import("@view/system/myjoin/add-form")
);
const ProjectJoinDetail = dynamicWrapper(() =>
  import("@view/system/myjoin/detail")
);
const ProjectJoinApply = dynamicWrapper(() => import("@view/system/join/"));
const ProjectJoinApplyAudit = dynamicWrapper(() =>
  import("@view/system/join/audit")
);
const ProjectJoinApplyDetail = dynamicWrapper(() =>
  import("@view/system/join/detail")
);
const VehiclePermit = dynamicWrapper(() =>
  import("@view/system/vehicle-permit/")
);
const VehiclePermitAdd = dynamicWrapper(() =>
  import("@view/system/vehicle-permit/add-form")
);
const VehiclePermitEdit = dynamicWrapper(() =>
  import("@view/system/vehicle-permit/edit-form")
);
const VehiclePermitDetail = dynamicWrapper(() =>
  import("@view/system/vehicle-permit/detail")
);
const PushMessageError = dynamicWrapper(() =>
  import("@view/system/push-message-error/")
);
const PushMessageErrorDetail = dynamicWrapper(() =>
  import("@view/system/push-message-error/detail")
);
const ApplyRole = dynamicWrapper(() => import("@view/system/apply-role/"));
const ApplyRoleAdd = dynamicWrapper(() =>
  import("@view/system/apply-role/add")
);
const ApplyRoleDetail = dynamicWrapper(() =>
  import("@view/system/apply-role/detail")
);
const ApplyRoleAudit = dynamicWrapper(() =>
  import("@view/system/apply-role-audit/")
);
const ApplyRoleAuditApply = dynamicWrapper(() =>
  import("@view/system/apply-role-audit/audit")
);
const ApplyRoleAuditDetail = dynamicWrapper(() =>
  import("@view/system/apply-role-audit/detail")
);
const ApplyRoleInfo = dynamicWrapper(() =>
  import("@view/system/apply-role-info")
);
const ApplyRoleInfoDetail = dynamicWrapper(() =>
  import("@view/system/apply-role-info/detail")
);
const ApplyRoleAllInfo = dynamicWrapper(() =>
  import("@view/system/apply-role-all-info")
);
const ApplyRoleAllInfoDetail = dynamicWrapper(() =>
  import("@view/system/apply-role-all-info/detail")
);

const TemporaryCard = dynamicWrapper(() =>
  import("@view/event/temporary-card/")
);
const TemporaryCardAdd = dynamicWrapper(() =>
  import("@view/event/temporary-card/add-form")
);
const TemporaryCardEdit = dynamicWrapper(() =>
  import("@view/event/temporary-card/edit-form")
);
const TemporaryCardDetail = dynamicWrapper(() =>
  import("@view/event/temporary-card/detail")
);

//工作流
const Template = dynamicWrapper(() => import("@view/workflow/template/"));
const TemplateAdd = dynamicWrapper(() =>
  import("@view/workflow/template/add-form")
);
const TemplateDetail = dynamicWrapper(() =>
  import("@view/workflow/template/detail")
);
const WorkflowAssign = dynamicWrapper(() => import("@view/workflow/assign/"));
const WorkflowAssignAdd = dynamicWrapper(() =>
  import("@view/workflow/assign/add-form")
);
const WorkflowAssignDetail = dynamicWrapper(() =>
  import("@view/workflow/assign/detail")
);

// 个人中心
const Info = dynamicWrapper(() => import("@view/account/info"));
const ChangePhone = dynamicWrapper(() => import("@view/account/changePhone"));
const ChangePwd = dynamicWrapper(() => import("@view/account/changePwd"));
const JoinProject = dynamicWrapper(() => import("@view/account/joinProject"));

//系统通知
const Notice = dynamicWrapper(() => import("@view/myadmin/notice"));
const NoticeAdd = dynamicWrapper(() => import("@view/myadmin/notice/add-form"));
const NoticeDetail = dynamicWrapper(() =>
  import("@view/myadmin/notice/detail")
);
const NoticeEdit = dynamicWrapper(() => import("@view/myadmin/notice/edit"));

const Selfeqpt = dynamicWrapper(() => import("@view/selfeqpt"));
const SelfeqptForm = dynamicWrapper(() => import("@view/selfeqpt/form"));
const MealVersion = dynamicWrapper(() => import("@view/meal"));
const AccessControlPrintTemplate = dynamicWrapper(() =>
  import("@view/access_control/template")
);

let _util = new CommonUtil();

@inject("menuState")
@observer
class Ppa extends React.Component {
  componentDidMount() {
    _util.removeSession("currentPage");
    _util.removeSession("scrollTop");
    _util.removeSession("pageSize");
  }

  render() {
    const { language } = this.props.menuState;

    return (
      <LocaleProvider
        locale={language === "en" ? null : language === "zh" ? zhCN : null}
      >
        <Provider menuState={menuState} appState={appState}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/welcome" component={WelcomePage} />
              <Route path="/error" component={ErrorPageOut} />
              <Route path="/forget" exact component={Forget} />
              {/* <Route path='/forget/step/two' component={ForgetStepTwo}/> */}
              <Route
                path="/"
                render={() => (
                  <SideLayout>
                    <Switch>
                      {/* 项目主页 */}
                      <PrivateRoute exact component={Home} path="/" />
                      <PrivateRoute
                        exact
                        path="/403"
                        component={ForbiddenPage}
                      />
                      <PrivateRoute exact path="/500" component={ErrorPage} />
                      <PrivateRoute
                        exact
                        path="/404"
                        component={NotFoundPage}
                      />
                      <PrivateRoute
                        exact
                        component={PassWord}
                        path="/password"
                      />

                      {/* MJK管理员主页 */}
                      <PrivateRoute
                        exact
                        component={MyAdminHome}
                        path="/super/home"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminUser}
                        path="/super/user"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminUserAdd}
                        path="/super/user/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminProject}
                        path="/super/project"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminProjectAdd}
                        path="/super/project/add/:id?"
                      />
                      {/* <PrivateRoute exact component={MyAdminProjectEdit} path='/super/project/edit' /> */}
                      <PrivateRoute
                        exact
                        component={MyAdminPermission}
                        path="/super/permission"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminPermissionAdd}
                        path="/super/permission/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminPermissionNew}
                        path="/super/permission-new"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminPermissionNewAdd}
                        path="/super/permission-new/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={MyAdminPermissionPro}
                        path="/super/permission-pro"
                      />
                      <PrivateRoute
                        exact
                        component={Privacy}
                        path="/super/privacy"
                      />
                      <PrivateRoute
                        exact
                        component={PrivacyAdd}
                        path="/super/privacy/add"
                      />
                      <PrivateRoute
                        exact
                        component={PrivacyEdit}
                        path="/super/privacy/edit"
                      />
                      <PrivateRoute
                        exact
                        component={PrivacyDetail}
                        path="/super/privacy/detail"
                      />

                      <PrivateRoute
                        exact
                        component={adminFactory}
                        path="/super/factory"
                      />
                      <PrivateRoute
                        exact
                        component={adminFactoryAdd}
                        path="/super/factory/add"
                      />
                      <PrivateRoute
                        exact
                        component={adminFactoryEdit}
                        path="/super/factory/edit"
                      />
                      <PrivateRoute
                        exact
                        component={adminFactoryDetail}
                        path="/super/factory/detail"
                      />

                      <PrivateRoute
                        exact
                        component={HelpList}
                        path="/super/help"
                      />
                      <PrivateRoute
                        exact
                        component={HelpAdd}
                        path="/super/help/add"
                      />
                      <PrivateRoute
                        exact
                        component={HelpDetail}
                        path="/super/help/detail"
                      />
                      <PrivateRoute
                        exact
                        component={BlankList}
                        path="/super/blank"
                      />
                      <PrivateRoute
                        exact
                        component={BlankListAdd}
                        path="/super/blank/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={BlankListEdit}
                        path="/super/blank/edit"
                      />
                      <PrivateRoute
                        exact
                        component={LoginLog}
                        path="/super/loginlog"
                      />
                      <PrivateRoute
                        exact
                        component={LoginLogDetail}
                        path="/super/loginlog/detail"
                      />
                      <PrivateRoute
                        exact
                        component={PrivacyLog}
                        path="/super/privacylog"
                      />
                      <PrivateRoute
                        exact
                        component={PrivacyLogDetail}
                        path="/super/privacylog/detail"
                      />
                      <PrivateRoute
                        exact
                        component={OperationLog}
                        path="/super/operation"
                      />
                      <PrivateRoute
                        exact
                        component={OperationLogDetail}
                        path="/super/operation/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Version}
                        path="/super/version"
                      />
                      <PrivateRoute
                        exact
                        component={MaterialType}
                        path="/material/type"
                      />
                      <PrivateRoute
                        exact
                        component={MaterialManage}
                        path="/material/manage"
                      />
                      <PrivateRoute
                        exact
                        component={MaterialManageHandle}
                        path="/material/manage/handle"
                      />
                      <PrivateRoute
                        exact
                        component={MaterialManageDetail}
                        path="/material/manage/detail/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={MaterialManageEdit}
                        path="/material/manage/edit/:id?"
                      />

                      <PrivateRoute
                        exact
                        component={ProjectSetting}
                        path="/system/settings"
                      />
                      <PrivateRoute
                        exact
                        component={User}
                        path="/system/user"
                      />
                      <PrivateRoute
                        exact
                        component={UserAdd}
                        path="/system/user/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={ContractorAdd}
                        path="/system/user/contractor-add"
                      />
                      <PrivateRoute
                        exact
                        component={UserDetail}
                        path="/system/user/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Feedback}
                        path="/system/feedback"
                      />
                      <PrivateRoute
                        exact
                        component={FeedbackDetail}
                        path="/system/feedback/detail"
                      />
                      <PrivateRoute
                        exact
                        component={PushMessage}
                        path="/system/message"
                      />
                      <PrivateRoute
                        exact
                        component={PushMessageAdd}
                        path="/system/message/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={PushMessageDetail}
                        path="/system/message/detail"
                      />

                      <PrivateRoute
                        exact
                        component={Costcenter}
                        path="/system/costcenter"
                      />
                      <PrivateRoute
                        exact
                        component={CostcenterAdd}
                        path="/system/costcenter/add"
                      />
                      <PrivateRoute
                        exact
                        component={CostcenterEdit}
                        path="/system/costcenter/edit"
                      />
                      <PrivateRoute
                        exact
                        component={CostcenterDetail}
                        path="/system/costcenter/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Department}
                        path="/system/department"
                      />
                      <PrivateRoute
                        exact
                        component={DepartmentAdd}
                        path="/system/department/add"
                      />
                      <PrivateRoute
                        exact
                        component={DepartmentEdit}
                        path="/system/department/edit"
                      />
                      <PrivateRoute
                        exact
                        component={DepartmentDetail}
                        path="/system/department/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Location}
                        path="/system/location"
                      />
                      <PrivateRoute
                        exact
                        component={LocationAdd}
                        path="/system/location/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={LocationDetail}
                        path="/system/location/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Area}
                        path="/system/area"
                      />
                      <PrivateRoute
                        exact
                        component={AreaAdd}
                        path="/system/area/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={AreaEdit}
                        path="/system/area/edit"
                      />
                      <PrivateRoute
                        exact
                        component={AreaDetail}
                        path="/system/area/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Action}
                        path="/system/action"
                      />
                      <PrivateRoute
                        exact
                        component={ActionAdd}
                        path="/system/action/add"
                      />
                      <PrivateRoute
                        exact
                        component={ActionEdit}
                        path="/system/action/edit"
                      />
                      <PrivateRoute
                        exact
                        component={ActionDetail}
                        path="/system/action/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Menu}
                        path="/system/menu"
                      />
                      <PrivateRoute
                        exact
                        component={MenuAdd}
                        path="/system/menu/add"
                      />
                      <PrivateRoute
                        exact
                        component={MenuEdit}
                        path="/system/menu/edit"
                      />
                      <PrivateRoute
                        exact
                        component={MenuDetail}
                        path="/system/menu/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Permission}
                        path="/system/permission"
                      />
                      <PrivateRoute
                        exact
                        component={PermissionAdd}
                        path="/system/permission/add"
                      />
                      <PrivateRoute
                        exact
                        component={PermissionEdit}
                        path="/system/permission/edit"
                      />
                      <PrivateRoute
                        exact
                        component={PermissionDetail}
                        path="/system/permission/detail"
                      />
                      <PrivateRoute exact component={Role} path="/user/role" />
                      <PrivateRoute
                        exact
                        component={RoleAdd}
                        path="/user/role/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={RoleDetail}
                        path="/user/role/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Register}
                        path="/system/register"
                      />
                      <PrivateRoute
                        exact
                        component={RegisterAudit}
                        path="/system/register/audit"
                      />
                      <PrivateRoute
                        exact
                        component={RegisterDetail}
                        path="/system/register/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={AllRegister}
                        path="/system/allregister"
                      />
                      <PrivateRoute
                        exact
                        component={AllRegisterDetail}
                        path="/system/allregister/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={AccessType}
                        path="/system/accesstype"
                      />
                      <PrivateRoute
                        exact
                        component={AccessTypeAdd}
                        path="/system/accesstype/add"
                      />
                      <PrivateRoute
                        exact
                        component={AccessTypeEdit}
                        path="/system/accesstype/edit"
                      />
                      <PrivateRoute
                        exact
                        component={AccessTypeDetail}
                        path="/system/accesstype/detail"
                      />
                      <PrivateRoute
                        exact
                        component={Channel}
                        path="/system/channel"
                      />
                      <PrivateRoute
                        exact
                        component={ChannelAdd}
                        path="/system/channel/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={ChannelDetail}
                        path="/system/channel/detail"
                      />

                      <PrivateRoute
                        exact
                        component={MapArea}
                        path="/system/map"
                      />

                      <PrivateRoute
                        exact
                        component={Reason}
                        path="/system/reason"
                      />
                      <PrivateRoute
                        exact
                        component={ReasonAdd}
                        path="/system/reason/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={ReasonDetail}
                        path="/system/reason/detail"
                      />
                      <PrivateRoute
                        exact
                        component={GoodsType}
                        path="/system/goodstype"
                      />
                      <PrivateRoute
                        exact
                        component={GoodsTypeAdd}
                        path="/system/goodstype/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={GoodsTypeDetail}
                        path="/system/goodstype/detail"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoin}
                        path="/system/myjoin"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoinAdd}
                        path="/system/myjoin/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoinDetail}
                        path="/system/myjoin/detail"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoinApply}
                        path="/system/join"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoinApplyAudit}
                        path="/system/join/audit"
                      />
                      <PrivateRoute
                        exact
                        component={ProjectJoinApplyDetail}
                        path="/system/join/detail"
                      />
                      <PrivateRoute
                        exact
                        component={VehiclePermit}
                        path="/system/vehiclepermit"
                      />
                      <PrivateRoute
                        exact
                        component={VehiclePermitAdd}
                        path="/system/vehiclepermit/add"
                      />
                      <PrivateRoute
                        exact
                        component={VehiclePermitEdit}
                        path="/system/vehiclepermit/edit"
                      />
                      <PrivateRoute
                        exact
                        component={VehiclePermitDetail}
                        path="/system/vehiclepermit/detail"
                      />
                      <PrivateRoute
                        exact
                        component={PushMessageError}
                        path="/system/pushmessageerror"
                      />
                      <PrivateRoute
                        exact
                        component={PushMessageErrorDetail}
                        path="/system/pushmessageerror/detail"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRole}
                        path="/system/rolerecord"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAdd}
                        path="/system/applyrole/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleDetail}
                        path="/system/applyrole/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAudit}
                        path="/system/rolerecord/pending"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAuditApply}
                        path="/system/applyroleaudit/audit/:id"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAuditDetail}
                        path="/system/applyroleaudit/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleInfo}
                        path="/system/applyroleinfo"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleInfoDetail}
                        path="/system/applyroleinfo/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAllInfo}
                        path="/system/allapplyroleinfo"
                      />
                      <PrivateRoute
                        exact
                        component={ApplyRoleAllInfoDetail}
                        path="/system/allapplyroleinfo/detail/:id"
                      />

                      <PrivateRoute
                        exact
                        component={TemporaryCard}
                        path="/event/temporarycard"
                      />
                      <PrivateRoute
                        exact
                        component={TemporaryCardAdd}
                        path="/event/temporarycard/add"
                      />
                      <PrivateRoute
                        exact
                        component={TemporaryCardEdit}
                        path="/event/temporarycard/edit"
                      />
                      <PrivateRoute
                        exact
                        component={TemporaryCardDetail}
                        path="/event/temporarycard/detail"
                      />

                      <PrivateRoute
                        exact
                        component={Template}
                        path="/workflow/template"
                      />
                      <PrivateRoute
                        exact
                        component={TemplateAdd}
                        path="/workflow/template/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={TemplateDetail}
                        path="/workflow/template/detail"
                      />
                      <PrivateRoute
                        exact
                        component={WorkflowAssign}
                        path="/workflow/manage"
                      />
                      <PrivateRoute
                        exact
                        component={WorkflowAssignAdd}
                        path="/workflow/manage/add/:id?"
                      />
                      <PrivateRoute
                        exact
                        component={WorkflowAssignDetail}
                        path="/workflow/manage/detail"
                      />

                      <PrivateRoute
                        exact
                        component={Info}
                        path="/account/info"
                      />
                      <PrivateRoute
                        exact
                        component={ChangePhone}
                        path="/account/changePhone"
                      />
                      <PrivateRoute
                        exact
                        component={ChangePwd}
                        path="/account/changePwd"
                      />
                      <PrivateRoute
                        exact
                        component={JoinProject}
                        path="/account/joinProject"
                      />
                      <PrivateRoute
                        exact
                        component={Notice}
                        path="/myadmin/notice"
                      />
                      <PrivateRoute
                        exact
                        component={NoticeAdd}
                        path="/myadmin/notice/add"
                      />
                      <PrivateRoute
                        exact
                        component={NoticeDetail}
                        path="/myadmin/notice/detail/:id"
                      />
                      <PrivateRoute
                        exact
                        component={NoticeEdit}
                        path="/myadmin/notice/edit/:id"
                      />
                      <PrivateRoute
                        exact
                        component={Selfeqpt}
                        path="/super/selfeqpt"
                      />
                      <PrivateRoute
                        exact
                        component={SelfeqptForm}
                        path="/super/selfeqpt/add"
                      />
                      <PrivateRoute
                        exact
                        component={SelfeqptForm}
                        path="/super/selfeqpt/edit/:id"
                      />
                      <PrivateRoute
                        exact
                        component={MealVersion}
                        path="/super/meal"
                      />
                      <PrivateRoute
                        exact
                        component={AccessControlPrintTemplate}
                        path="/super/access_control/print/template"
                      />
                      <Redirect from="*" to="/404" />
                    </Switch>
                  </SideLayout>
                )}
              />
            </Switch>
          </Router>
        </Provider>
      </LocaleProvider>
    );
  }
}

export default DragDropContext(HTML5Backend)(Ppa);
