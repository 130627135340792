module.exports = {
  "app.commonjs.placeholder.select_time": "select time",
  "app.commonjs.placeholder.select_date": "select date",
  "app.commonjs.placeholder.nodata": "no data",
  "app.commonjs.message.error_format": "error format",
  "app.commonjs.message.over_size": "max size 3M",
  "app.commonjs.message.size": "max size",
  "app.commonjs.message.unit": "MB!",
  "app.commonjs.message.uploaded": "uploaded",
  "app.commonjs.message.upload_failed": "upload failed",
  "app.commonjs.message.delete_first": "delete first",
  "app.commonjs.message.deleted": "delete success",
  "app.commonjs.message.selectdata": "select data",
  "app.carryout.status.created": "Create",
  "app.carryout.status.to_assigned": "Pending",
  "app.carryout.status.wait_approve": "Pending",
  "app.carryout.status.approved": "Approved",
  "app.carryout.status.not_approved": "Rejected",
  "app.carryout.status.withdraw": "Withdraw",
  "app.carryout.status.return_back": "Return",
  "app.carryout.status.bewithdraw": "Withdraw",
  "app.carryout.status.bereturn": "Returned",
  "app.carryout.status.closed": "Closed",
  "app.carryout.status.exception_closed": "Abnormal Closure",
  "app.carryout.status.not_active": "Inactivated",
  "app.carryout.status.submit": "Submit",
  "app.project.stage.single": "One-Line Text",
  "app.project.stage.number": "Number",
  "app.project.stage.textarea": "Multi-Line",
  "app.project.stage.single_select": "Single Select",
  "app.project.stage.multi_select": "Multi Select",
  "app.project.stage.radio": "Single Radio",
  "app.project.stage.multi_radio": "Multi Radios",
  "page.oneStop.cardOperation.oneTime": "OneTime",
  "page.oneStop.cardOperation.long": "LongTerm",
  "page.oneStop.cardOperation.photo1": "Insider",
  "page.oneStop.cardOperation.photo2": "Outsider",
  "page.oneStop.cardAnnual.recordOption1": "Photo",
  "page.oneStop.cardAnnual.recordOption2": "Video",
  "page.oneStop.cardAnnual.recordOption3": "Sound",
  "page.oneStop.cardAnnual.recordOption4": "Others",
  "page.oneStop.cardOperation.photo3": "Dev",
  "page.oneStop.cardOperation.photo4": "Prod",
  "page.oneStop.cardOperation.photo5": "Admin",
  "page.oneStop.cardOperation.photo6": "Others",
  "app.page.content.none": "-",
  "page.oneStop.cardOperation.photo7": "Internal",
  "page.oneStop.cardOperation.photo68": "External",
  "page.oneStop.cardOperation.gName": "Device Name",
  "page.oneStop.cardOperation.serialNumber": "Serial",
  "page.oneStop.cardOperation.fixedAsset": "Asset No.",
  "page.oneStop.cardOperation.modelNumber": "Computer Model",
  "app.page.text.whiteCard": "Plain Card",
  "app.page.text.chipCard": "Chip Card",
  "app.page.text.yes": "Yes",
  "app.page.text.no": "No",
  "page.construction.monitor.contractor2": "Contractor",
  "page.construction.monitor.contractor": "Supplier",
  "app.component.side_layout.enter_opinion": "Pls Input Opinions",
  "app.component.side_layout.submitted": "Submit Success",
  "app.component.side_layout.logout_success": "Logout Success",
  "page.event.accessrecord.month": "Month",
  "page.event.accessrecord.day": "Day",
  "page.event.accessrecord.hour": "H",
  "page.event.accessrecord.minute": "M",
  "page.event.accessrecord.second": "S",
  "page.oneStop.cardOperation.persNoId":"Employee No.",
  "page.oneStop.cardOperation.originalCard":"original Card No.",
  "app.component.exception.403": "Sorry, you don't have access to this page.",
  "app.component.exception.404": "Sorry, the page you visited not found.",
  "app.component.exception.500": "Sorry, the server is error.",
  "app.component.exception.notice": "You need to change your password before you can continue to visit.",
  "app.component.exception.attention": "Attention",
  "app.component.exception.error": "Sorry, there was a mistake.",
  "page.construction.projectAudit.staffInfo1":"Full Name",
  "page.keys.annual.costCenter":"Cost Center",
  "page.keys.annual.department":"Department",
  "page.oneStop.cardOperation.cardNumber":"Card No."
};
